<template>
  <v-card>
    <v-container>
      <v-card-text>
        <v-form v-model="isFormValid" ref="form" form="form" id="form">
          <v-card>
            <v-container>
              <v-card-title>
                <span class="pl-1 primary--text">{{ title }}</span>
              </v-card-title>
              <v-row>
                <!-- Entidad -->
                <v-col cols="4" sm="6" md="5" class="py-0">
                  <v-text-field
                    type="text"
                    outlined
                    dense
                    filled
                    readonly
                    label="Entidad efectora"
                    v-model="entidad"
                    autocomplete="off"
                    disabled
                  ></v-text-field>
                </v-col>
                <!-- Número de orden -->
                <v-col cols="4" sm="6" md="2" class="py-0">
                  <v-text-field
                    type="text"
                    outlined
                    dense
                    filled
                    readonly
                    label="Orden"
                    v-model="orden"
                    autocomplete="off"
                    disabled
                  ></v-text-field>
                </v-col>

                <!-- convenio -->
                <v-col cols="4" sm="6" md="5" class="py-0">
                  <v-text-field
                    type="text"
                    outlined
                    dense
                    filled
                    readonly
                    label="Convenio/Plan"
                    v-model="convenio"
                    autocomplete="off"
                    disabled
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <!-- afiliado -->
                <v-col cols="6" sm="6" md="6" class="py-0">
                  <v-text-field
                    type="text"
                    outlined
                    dense
                    filled
                    readonly
                    label="Afiliado"
                    v-model="afiliado"
                    autocomplete="off"
                    disabled
                  ></v-text-field>
                </v-col>
                <!-- Solicitante -->
                <v-col cols="6" sm="6" md="6" class="py-0">
                  <v-text-field
                    type="text"
                    outlined
                    dense
                    filled
                    readonly
                    label="Solicitante"
                    v-model="solicitante"
                    autocomplete="off"
                    disabled
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <!-- Fecha de prescripción -->
                <v-col cols="12" sm="12" md="4" class="py-0">
                  <v-menu
                    ref="fecha-cobro"
                    v-model="menuFechaPrescrip"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        ref="fecha-cobro-textField"
                        v-model="fechaPrescrip"
                        label="Fecha de prescripción"
                        :append-icon="calendarIcon"
                        v-mask="'##/##/####'"
                        @blur="fechaPrescrip = parseDateToIso(fechaPrescrip)"
                        outlined
                        dense
                        disabled
                        filled
                        clearable
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="fechaPrescrip"
                      no-title
                      @change="fechaPrescrip = formatDate(fechaPrescrip)"
                      @input="menuFechaPrescrip = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <!-- Fecha de realización -->
                <v-col cols="12" sm="12" md="4" class="py-0">
                  <v-menu
                    ref="fecha-cobro"
                    v-model="menuFechaRealizacion"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        ref="fecha-cobro-textField"
                        v-model="fechaRealizacion"
                        label="Fecha de realización"
                        :append-icon="calendarIcon"
                        hint="Formato DD/MM/AAAA"
                        v-mask="'##/##/####'"
                        outlined
                        dense
                        disabled
                        filled
                        clearable
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="fechaRealiz"
                      no-title
                      @change="fechaRealizacion = formatDate(fechaRealiz)"
                      @input="menuFechaRealizacion = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <!-- Diagnostico CIE -->
              <v-row>
                <v-col cols="12" sm="6" md="12" class="py-0">
                  <v-text-field
                    type="text"
                    outlined
                    dense
                    filled
                    readonly
                    label="Diagnóstico CIE-10"
                    v-model="diagnosticoCie"
                    autocomplete="off"
                    disabled
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <!-- Diagnostico presuntivo -->
                <v-col cols="12" sm="6" md="12" class="py-0">
                  <v-text-field
                    type="text"
                    outlined
                    dense
                    filled
                    readonly
                    label="Diagnóstico presuntivo"
                    v-model="diagnosticoPresuntivo"
                    autocomplete="off"
                    disabled
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <!-- Area de atencion -->
                <v-col cols="12" sm="6" md="3" class="py-0">
                  <v-text-field
                    type="text"
                    outlined
                    dense
                    filled
                    readonly
                    label="Area de atención"
                    v-model="areaAtencion"
                    autocomplete="off"
                    disabled
                  ></v-text-field>
                </v-col>
                <!-- estado -->
                <v-col cols="12" sm="6" md="3" class="py-0">
                  <v-text-field
                    type="text"
                    outlined
                    dense
                    filled
                    readonly
                    label="Estado"
                    v-model="estado"
                    autocomplete="off"
                    disabled
                  ></v-text-field>
                </v-col>
                <!-- Codigo autorizacion -->
                <v-col cols="12" sm="6" md="3" class="py-0">
                  <v-text-field
                    type="text"
                    outlined
                    dense
                    filled
                    readonly
                    label="Código de autorización"
                    v-model="codAutorizacion"
                    autocomplete="off"
                    disabled
                  ></v-text-field>
                </v-col>
                <!-- Coseguro neto -->
                <v-col cols="12" sm="6" md="3" class="py-0">
                  <v-text-field
                    type="text"
                    outlined
                    dense
                    filled
                    readonly
                    label="Coseguro Neto"
                    v-model="cosNeto"
                    autocomplete="off"
                    disabled
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card>

          <v-card class="mt-2">
            <v-card-title>
              <span class="primary--text">{{ titlePracticas }}</span>
            </v-card-title>
            <v-data-table
              :headers="headers"
              :items="itemsPracticas"
              class="elevation-1"
              :hide-default-footer="true"
              dense
            >
            </v-data-table>
          </v-card>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModalVerAutorizacion()"> Cerrar </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import rules from "@/utils/helpers/rules";
import { mapActions } from "vuex";
import enums from "@/utils/enums/index.js";
import { mask } from "vue-the-mask";
export default {
  name: "VerAutorizacionCoseguro",
  directives: { mask },
  props: {
    nuevoDevengId: {
      type: Number,
      required: true
    },
    numeroOrden: {
      Type: String,
      required: true
    }
  },
  data: vm => ({
    title: "Datos de la solicitud de autorización",
    titlePracticas: "Listado de prácticas",
    isFormValid: false,
    rules: rules,
    calendarIcon: enums.icons.CALENDAR,
    itemsPracticas: [],
    menuFecha: false,
    entidad: null,
    orden: null,
    fechaRealiz: null,
    afiliado: null,
    convenio: null,
    solicitante: null,
    menuFechaPrescrip: null,
    menuFechaRealizacion: null,
    fechaPrescrip: null,
    fechaRealizacion: null,
    diagnosticoCie: null,
    diagnosticoPresuntivo: null,
    areaAtencion: null,
    estado: null,
    codAutorizacion: null,
    cosNeto: null,
    fechaInicioCobertura: new Date().toISOString().substr(0, 10),
    headers: [
      {
        text: "Fecha realización",
        align: "start",
        value: "fechaRea",
        sortable: true
      },
      {
        text: "Prestador",
        align: "start",
        value: "prestadorNomCompleto",
        sortable: false
      },
      {
        text: "Nomenclador",
        align: "start",
        value: "nomencladorTipo",
        sortable: false
      },
      {
        text: "Práctica",
        align: "start",
        value: "nomencladorCodigo",
        sortable: false
      },
      {
        text: "Cantidad",
        align: "start",
        value: "cantidad",
        sortable: false
      }
    ]
  }),
  created() {
    this.verAutorizacion();
    this.verPractica();
    this.numeroOrden;
  },
  methods: {
    ...mapActions({
      getAfiliado: "afiliaciones/getAfiliadoSimpleById",
      setBenApeCodificado: "afiliaciones/setBenApeCodificado",
      generarAfiCodificado: "afiliaciones/generarAfiCodificado",
      verPracticasAutorizacion: "configuracion/verPracticasAutorizacion",
      verAutorizacionCompleta: "configuracion/verAutorizacionCompleta",
      setAlert: "user/setAlert"
    }),
    async verPractica() {
      const response = await this.verPracticasAutorizacion(this.nuevoDevengId);
      this.itemsPracticas = response;
    },
    async verAutorizacion() {
      const response = await this.verAutorizacionCompleta(this.nuevoDevengId);
      const respo = response[0];
      this.entidad = respo.origenNomCompleto;
      this.orden = respo.nroOrden;
      this.afiliado = respo.benNomCompleto;
      this.convenio = respo.planNombre;
      this.solicitante = respo.prestadorNomCompleto;
      this.fechaPrescrip = respo.fecPre;
      this.fechaRealizacion = respo.fecRea;
      this.diagnosticoCie = respo.especialidadCie;
      this.diagnosticoPresuntivo = respo.diagnostico;
      this.areaAtencion = respo.area;
      this.estado = respo.estadoAutorizacion;
      this.codAutorizacion = respo.codigoAutorizacion;
      this.cosNeto = respo.coseNeto;
    },
    parseDateToIso(date) {
      if (!date) return null;
      if (date.length === 10) {
        const [day, month, year] = date.split("/");
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      }
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    closeModalVerAutorizacion() {
      this.$emit("closeModalVerAutorizacion");
    }
  }
};
</script>

<style scoped>
::v-deep .theme--light.v-input input {
  color: rgb(56, 56, 56);
}

::v-deep .v-select__selection,
.v-select__selection--comma,
.v-select__selection--disabled input {
  color: black !important;
}

::v-deep .v-input__slot {
  cursor: context-menu !important;
}
</style>
